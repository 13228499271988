
















































































import {
  BButton,
  BCard,
  BCol,
  BDropdown,
  BFormSelect,
  BDropdownItem,
  BFormInput,
  BPagination,
  BRow,
  BTable,
  BSpinner,
  BBadge,
} from "bootstrap-vue";
import { Component, Vue } from "vue-property-decorator";
import { errorAlert, successAlert } from "@/libs/sweetAlerts/alerts";
import { TypePartenaireFASTT } from "@/api/models/FASTT/typesPartenaire/typesPartenaire";
import AddPartenaire from "./AddPartenaire.vue"
import { StatusEnum } from "@/api/models/enums/statusPartenaireFASTT";

@Component({
components: {
  BCard,
  BRow,
  BCol,
  BButton,
  BFormInput,
  BTable,
  BBadge,
  BSpinner,
  BPagination,
  BDropdown,
  BDropdownItem,
  BFormSelect,
  AddPartenaire
},
})
export default class DetailsTypesPartenaireFASTT extends Vue {
  statusPartenairesFASTT = StatusEnum;
  typeSelected: TypePartenaireFASTT | null = null;
  mainLoading = true;
 
  typePartenaireId = this.$route.params.id;
  isAddNewMembreSidebarActive = false;

  tableColumns = [
    { key: "nom", sortable: true },
    { key: "ville", sortable: true },
    { key: "telephone1", label: "Téléphone", sortable: true },
    { key: "statut", label: "statut", sortable: true },
    { key: "actions", sortable: false }
  ];

  async created() {
    this.mainLoading = true;
    await this.loadTypePartenaire()
    this.mainLoading = false;
  }

  goToList() {
    this.$router.push({ name: "types-partenaires-fastt" });
  }

  async loadTypePartenaire() {
    await this.$http.myressif.typesPartenaireFASTT
      .getById(this.$route.params.id)
      .then((response: TypePartenaireFASTT) => {
        this.typeSelected = response;
      });
  }

  askDelete(id: string) {
    this.$bvModal
    .msgBoxConfirm("Etes-vous sûr de vouloir supprimer ce membre ?", {
        title: "Confirmation de suppression",
        size: "sm",
        okVariant: "primary",
        okTitle: "Oui",
        cancelTitle: "Non",
        cancelVariant: "outline-secondary",
        hideHeaderClose: false,
        centered: true,
    })
    .then(async (value) => {
        if (value === true) {
        await this.confirmDelete(id);
        } else {
        return;
        }
    });
  }

  async confirmDelete(id: string) {
    await this.$http.myressif.typesPartenaireFASTT
        .deleteMembre(this.$route.params.id, id)
        .then(
            async (response: string) => {
            successAlert.fire({
                title: "Suppression d'un membre",
                text: "Suppression effectuée avec succès",
            });
            },
            (error: any) => {
            if (
                error?.response?.data?.errors &&
                Object.keys(error.response.data.errors).length
            ) {
                errorAlert.fire({
                text: error.response.data.errors[
                    Object.keys(error.response.data.errors)[0]
                ],
                });
            } else {
                errorAlert.fire({
                text: error?.response?.data?.detail ?? error.message,
                });
            }
            }
        )
        .finally(async () => {
            await this.loadTypePartenaire();
        });
  }
}

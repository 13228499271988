











































































import {
  BSidebar,
  BForm,
  BButton,
  BCard,
  BCol,
  BFormCheckbox,
  BDropdown,
  BFormGroup,
  BDropdownItem,
  BFormInput,
  BPagination,
  BFormSelect,
  BRow,
  BTable,
  BSpinner,
} from "bootstrap-vue";
import { Component, Prop, Vue } from "vue-property-decorator";
import { ValidationProvider, ValidationObserver } from "vee-validate";
import { required } from "@validations";
import SearchableVueSelect from "@/components/selects/SearchableVueSelect.vue";
import { errorAlert, successAlert } from "@/libs/sweetAlerts/alerts";
import { TypePartenaireFASTT, UpdateTypePartenaireFasttModel } from "@/api/models/FASTT/typesPartenaire/typesPartenaire";
import { PartenaireFASTT } from "@/api/models/FASTT/partenaires/partenaire";
import { PaginatedList } from "@/api/models/common/paginatedList";

@Component({
  components: {
    BCard,
    BRow,
    BCol,
    BButton,
    BFormInput,
    BTable,
    BPagination,
    BFormSelect,
    BDropdown,
    BDropdownItem,
    BSidebar,
    BForm,
    BFormGroup,
    BFormCheckbox,
    BSpinner,
    ValidationProvider,
    ValidationObserver,
    SearchableVueSelect,
  },
  name: "update-types-partenaire",
})
export default class AddPartenaire extends Vue {
  @Prop({ required: true, type: Boolean }) isAddNewPartenaireSidebarActive!: boolean;
  @Prop({ required: true }) typePartenaireId!: string;

  loading = false;
  required = required;

  payload: any = {};
  
  searchPartenairesTotalCount = 0;
  searchPartenairesItems: PartenaireFASTT[] = [];

  async created(params: any = null) {
    await this.loadPartenaires(params)
  }

  async loadPartenaires(params: any) {
    await this.$http.myressif.partenairesFASTT
      .paginatedList(
        params?.pageNumber ?? 1,
        params?.pageSize ?? 10,
        params?.search ?? ""
      )
      .then((res: any) => {
        this.searchPartenairesTotalCount = res.totalCount;
        this.searchPartenairesItems = this.searchPartenairesItems!.concat(res.items.filter((ri: any) => !this.searchPartenairesItems!.some(lsi => lsi.id == ri.id)))
        this.loading = false;
      });
  }

  async submit() {
    this.loading = true;
    // await this.$http.myressif.typesPartenaireFASTT
    //   .update(
    //     this.typePartenaireId,
    //     this.typePartenaire
    //   )
    //   .then(
    //     async (response: any) => {
    //       successAlert.fire({
    //         timer: 3500,
    //         title: "Modification du type de partenaire",
    //         text: "Type modifié avec succès",
    //       });
    //       this.resetForm();
    //     },
    //     (error: any) => {
    //       if (
    //         error?.response?.data?.errors &&
    //         Object.keys(error.response.data.errors).length
    //       ) {
    //         errorAlert.fire({
    //           text: error.response.data.errors[
    //             Object.keys(error.response.data.errors)[0]
    //           ],
    //         });
    //       } else {
    //         errorAlert.fire({
    //           text: error?.response?.data?.detail ?? error.message,
    //         });
    //       }
    //     }
    //   )
    //   .finally(() => {
    //     this.$emit("refresh-types");
    //     this.$emit("update:is-update-type-sidebar-active", false);
    //     this.loading = false;
    //   });
  }
}
